import { withoutLeadingSlash, parseURL } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'
import { createOperationsGenerator } from '#image'

export const operationsGenerator = createOperationsGenerator({
  keyMap: {
    format: 'fm',
    width: 'width',
    height: 'height',
    focus: 'f',
    radius: 'r',
    quality: 'q',
    background: 'bg'
  },
  valueMap: {
    format: {
      jpeg: 'jpg'
    },
    fit: {
      cover: 'crop',
      contain: 'fill',
      fill: 'scale',
      thumbnail: 'thumb'
    }
  },
  joinWith: '&',
  formatter: (key, value) => {
    if (key === 'fm' && value === 'jpg') {
      return `IMGT=A27`
    }
    if (key === 'fm' && value === 'webp') {
      return `IMGT=W27`
    }
    if (key === 'fm' && value === 'png') {
      return `IMGT=P27`
    }
    return `${key}=${value}`
  }
})

export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}) => {
  const operations = operationsGenerator(modifiers)

  const { protocol, host, pathname, search } = parseURL(src)

  const path = `${protocol}//${host}${pathname}${search}`

  const tURL = new URL(path)

  tURL.searchParams.delete('fm')
  tURL.searchParams.delete('width')
  tURL.searchParams.delete('IMGT')

  if (modifiers.format === 'jpeg' && tURL.searchParams.get('BKGND') === '9') {
    tURL.searchParams.set('BKGND', '0')
  }

  const url = withoutLeadingSlash(`${tURL.href}&${operations}`)
    .replace(/%2C/g, ',')
    .replace(/&amp;/g, '&')
  return {
    url
  }
}
