
import * as contentfulRuntime$Hcw4JY2nZj from '/build/node_modules/@nuxt/image/dist/runtime/providers/contentful'
import * as BBDRuntime$0qPiKMPmIX from '/providers/BBD.ts'
import * as ipxRuntime$xgpwhEg0Ws from '/build/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "images.ctfassets.net"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['contentful']: { provider: contentfulRuntime$Hcw4JY2nZj, defaults: {"baseURL":"https://images.ctfassets.net"} },
  ['BBD']: { provider: BBDRuntime$0qPiKMPmIX, defaults: {"baseURL":"https://europe.starconnect-ce.i.mercedes-benz.com"} },
  ['ipx']: { provider: ipxRuntime$xgpwhEg0Ws, defaults: {} }
}
        